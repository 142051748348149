<template>
  <b-card :title="cardTitle">
    <b-media>
      <template #aside>
        <b-avatar
            ref="previewEl"
            :src="(changed ? url : (url || imageUrl))"
            size="90px"
            rounded
        />
      </template>
      <div class="d-flex flex-wrap align-items-end">
        <b-button
            class="ml-1"
            variant="success"
            @click="$refs.refInputEl.click()"
        >
          <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="addAvatar"
          />
          <span v-if="url || (!changed && imageUrl)">
                  {{ $t('Update') }}
                </span>
          <span v-else>
                  {{ $t('Add') }}
                </span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none"/>
        </b-button>
        <b-button
            v-if="url || (!changed && imageUrl)"
            variant="danger"
            class="ml-1"
            @click="deleteAvatar"
        >
          <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none"/>
        </b-button>
      </div>
    </b-media>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BFormFile,
  BMedia
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BFormFile,
    BMedia
  },

  props: {
    currentItem: {
      type: Object,
      default: () => {
      },
      required: true
    },
    uploadUrl: {
      type: String,
      default: () => "",
      required: true
    },
    deleteUrl: {
      type: String,
      default: () => "",
      required: true
    },
    imageUrl: {
      type: String|null,
      default: () => "",
      required: true
    },
    cardTitle: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      changed: false,
      url: this.imageUrl
    }
  },
  directives: {
    Ripple,
  },
  name: 'SingleImage',
  methods: {
    getImage() {
      return (this.url ? this.url : this.imageUrl)
    },
    addAvatar(event) {
      if (event) event.preventDefault()

      const reader = new FileReader()
      let file = this.$refs.refInputEl.files[0]
      if (file) {
        reader.readAsDataURL(file)

        reader.onload = (evt) => {
          let base64 = evt.target.result
          this.url = base64
          this.changed = true

          this.uploadAvatar(file)
        }
      }
    },

    uploadAvatar(base64) {
      let formData = new FormData()
      formData.append('files', base64)

      this.$http
          .post(this.uploadUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.$bvToast.toast(this.$i18n.t('Image uploaded successfully'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Image not uploaded'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },
    deleteAvatar() {
      this.$http
          .delete(this.deleteUrl)
          .then((response) => {
            this.url = null
            this.changed = true

            this.$bvToast.toast(this.$i18n.t('Image deleted'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t('Image not deleted'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          })
    },
  }
}
</script>

<style scoped>

</style>
